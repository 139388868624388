<template>
  <div class="uploader-box">
    <slot name="uploader">
      <div :id="container">
        <a-tooltip placement="top">
          <template slot="title">
            <span>请选择附件</span>
          </template>
          <a-icon type="paper-clip" class="cursor-pointer default-hover" :id="pickfiles"/>
        </a-tooltip>
      </div>
    </slot>
    <ul class="earl-upload-list w-450 m-t-12" v-if="!notShowDefaultList && files.length > 0">
      <li class="earl-upload-list-file" :key="file.id" v-for="file in files">
        <a-row>
          <a-col span="20">
            <span><a-icon :type="format(file)"/>&nbsp;{{file.name}}&nbsp;{{humanReadableUnits(file.size)}}</span>
          </a-col>
          <a-col span="1">
            <a-icon type="close" class="earl-upload-list-remove" v-show="!file.saveToServer"
                    @click.native="removeFile(file)"/>
          </a-col>
        </a-row>
        <a-row>
          <a-col span="24">
            <a-progress :strokeWidth="2" :percent="parseInt(file.percent,10)"
                        :status="file['error']?'exception':(file.realSuccess?'success':'active')"/>
          </a-col>
        </a-row>
      </li>
    </ul>
  </div>
</template>
<script>

  /* eslint-disable */

  import moxie from '../../plugins/plupload/moxie'
  import qiniu from '../../plugins/qiniu/qiniu'
  import '../../plugins/plupload/plupload.min'
  import SERVICE_URLS from '../../api/service.url'
  import { documentVo } from '../../api/vo/settings/documentVO'
  import { fileIconFormat, humanReadableUnits } from '../../utils/utils'
  import VueCookie from 'vue-cookie'
  import * as types from '../../store/mutation-types'

  export default {
    props: {
      container: {
        type: String
      },
      pickfiles: {
        type: String
      },
      documentDirId: {
        type: Number
      },
      content: {
        type: Object
      },
      notShowDefaultList: {
        type: Boolean,
        default: false
      },
      useCustomSave: {
        type: Boolean,
        default: false
      },
      maxSize: {
        type: String,
        default: '500mb'
      },
      updaloadTypes: {
        type: Array,
        default: function () {
          return [];
        },
      },
      autoStart: {
        type: Boolean,
        default: true
      },
      multiSelection: {
        type: Boolean,
        default: false
      },
      filters: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    data () {
      return {
        uploader: null,
        //上传文件数组
        files: [],
        typeGuardConfigs: {
          image: ['image/jpeg', 'image/jpg', 'image/png'],
          jpg: ['image/jpeg', 'image/jpg'],
          png: ['image/png'],
          gif: ['image/gif'],
          mp4: ['video/mp4'],
          mpeg: ['video/mpeg'],
          avi: ['video/x-msvideo'],
          txt: ['text/plain'],
          rtf: ['application/rtf'],
          doc: ['application/msword'],
          docx: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
          xls: ['application/vnd.ms-excel'],
          xlsx: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
          zip: ['application/zip'],
          rar: ['application/octet-stream'],
          pdf: ['application/pdf'],
        },
      }
    },
    watch: {
      multiSelection (value) {
        this.uploader.setOption('multi_selection', value)
      }
    },
    mounted () {
      const self = this
      this.uploader = Qiniu.uploader({
        runtimes: 'html5,flash,html4',
        browse_button: self.pickfiles,
        filters: self.filters,
        container: self.container,
        max_file_size: self.maxSize || '500mb',
        flash_swf_url: '/plugins/plupload/Moxie.swf',
        dragdrop: true,
        drop_element: self.container,
        chunk_size: '4mb',
        multi_selection: self.multiSelection && true,
        uptoken_url: SERVICE_URLS.document.qiniu.upToken.path,
        domain: 'https://srcp.fangshuoit.com/',
        auto_start: self.autoStart || false,
        init: {
          'FilesAdded': function(up, files) {
            let filterFiles = []
            let filterTypes = []
            self.updaloadTypes.map(function (item) {
              filterTypes.push(...self.typeGuardConfigs[item])
            })

            files.map(function (file) {
              console.log("11=", file.type)
              if (self.updaloadTypes.length > 0 && filterTypes.indexOf(file.type) === -1) {
                up.removeFile(file)
              } else {
                file.realSuccess = false
                file.error = false
                file.folderId = self.documentDirId
                filterFiles.push(file)
                self.files.push(file)
              }
            })
            self.$emit('onFilesAdded', filterFiles, self.files)

            if (filterFiles.length < files.length) {
              alert('只允许上传"' + self.updaloadTypes.join('","') + '"格式的文件！');
            }
          },
          'BeforeUpload': function(up, file) {
            self.$emit('onBeforeUpload', up, file)
          },
          'QueueChanged': function(up, files) {
          },
          'UploadProgress': function(up, file) {

          },
          'FileUploaded': function(up, file, info) {
            file['saveToServer'] = true
            //调用后台文件保存接口
            let document = documentVo()
            document.fileSize = file.size
            const res = JSON.parse(info)
            document.fileType = res.type
            document.fileUrl = res.key
            document.name = file.name
            document.newName = res.key
            document.w = res.w
            document.h = res.h
            if (file.folderId) {
              document.parentId = file.folderId
            } else {
              document.parentId = null
            }
            self.save(document, file)
          },
          'Error': function(up, err, errTip) {
            if (self.error) {
              self.error(up, err, errTip)
            }
          }
          ,
          /**
           * @return {string}
           */
          'Key': function(up, file) {
            let key
            const filename = file.name
            const tempArr = filename.split('.')
            let ext
            if (tempArr.length === 1 || (tempArr[0] === '' && tempArr.length === 2)) {
              ext = ''
            } else {
              ext = tempArr.pop().toLowerCase() //get the extension and make it lower-case
            }
            key = (ext ? file.id + '.' + ext : file.id)
            // do something with key
            return key
          }
        },
        'FilesAdded': function(up, files) {
          alert(132)
          return false;
        }
      })
    },
    methods: {
      //只有存在文件需要上传时，才掉此方法，否则返回 false
      submit () {
        if (this.files.length > 0 && this.uploader.total.percent !== 100) {
          this.uploader.start()
          return true
        }
        return false
      },
      format (file) {
        return fileIconFormat(file.name)
      },
      humanReadableUnits (bytes) {
        return humanReadableUnits(bytes)
      },
      removeFile (file) {
        if (this.files.indexOf(file) !== -1) {
          this.uploader.removeFile(file.id)
          for (let i = 0; i < this.files.length; i++) {
            if (this.files[i].id === file.id) {
              this.files.splice(i, 1)
            }
          }
          this.$emit('onFileRemove', file, this.files)
        }
      },
      //清空所有文件
      clearFiles () {
        this.files = []
        this.uploader.splice(0, this.uploader.files.length)
      },
      /**
       * 保存上传的文件信息至本地数据库
       * 允许增加一个自定义的保存方法 customSave
       * customSave 需要处理文件保存结果
       */
      save (document, file) {
        if (this.useCustomSave) {
          // 手动处理后，需要正确设置 file['realSuccess']
          this.$emit('customSave', document, file)
        } else {
          this.$http(this, {
            url: SERVICE_URLS.document.create,
            data: document,
            loading: 'no',
            noTips: true,
            success: (data) => {
              file['realSuccess'] = true
              let body = data.body
              // 文件服务端ID
              file['documentId'] = body.id
              body['newUploadFile'] = true
              file['saveToServer'] = false
              //每一个文件上传成功都会触发
              this.$emit('onOneUploadComplete', body, file)
              this.checkAllSuccess()
            },
            error: (err, data) => {
              console.log(err)
              console.log(data)
              file['saveToServer'] = false
              file['error'] = true
              this.$emit('onError', this.uploader, 'save.error', '文件保存错误')
            }
          })
        }
      },
      checkAllSuccess () {
        //循环file  判断文件是否全部真正的上传成功，成功下，才执行onUploadComplete
        let success = true
        for (let i = 0, l = this.uploader.files.length; i < l; i++) {
          // 文件处理错误 也会触发完成回调
          if (!this.uploader.files[i]['realSuccess']) {
            success = false
            break
          }
        }
        if (success) {
          //每一个所有文件上传成功会触发
          this.$emit('onUploadComplete')
        }
      }
    }
  }
</script>
